import React from 'react'

import {
  ThemeProvider,
  CssBaseline
} from '@material-ui/core'

import Theme from './src/index.theme'

const ThemeTiradentes: React.FC = ({ children }): JSX.Element => (
  <>
    <ThemeProvider theme={ Theme }>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </>
)

export default ThemeTiradentes

import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  listItemGutters: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 24,
  },
  listItemIcon: {
    minWidth: 30,
    color: theme.palette.primary.light,
  },
  listItemText: { marginLeft: '10px', },
  item: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    transition: 'all ease 1s'
  },
  itemColor: { color: '#808080' },
  itemActive: { color: `${ theme.palette.primary.light } !important` },
  listItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '6px 6px',
  },
  listItemOpen: { color: '#000000' },
  card: {
    display: 'flex',
    alignSelf: 'center',
    justifySelf: 'center',
    fontSize: '22px'
  },
  arrowIconButton: { padding: 0 },
}))

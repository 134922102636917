import { createTheme } from '@material-ui/core'

export default createTheme({
  palette: {
    primary: { main: '#066cc0' },
    secondary: { main: '#04afd6' },
    background: { default: '#eaedf5' },
    text: { primary: '#757575' }
  }
})

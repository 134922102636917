import {
  useMemo,
  FC
} from 'react'
import { NavLink } from 'react-router-dom'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import clsx from 'clsx'

import { Menu } from '@icr/web/src/routes/types'

import useStyles from './styles'

interface Props {
  menu: Menu;
  menuOpen: boolean;
}

const ItemMenu: FC<Props> = ({ menu, menuOpen }) => {
  const classes = useStyles()

  const item = useMemo(
    () => {
      let content = (
        <>
          {menu.Icon && (
            <ListItemIcon className={ classes.listItemIcon }>
              <menu.Icon />
            </ListItemIcon>
          )}
          {menuOpen && (
            <ListItemText
              primary={ menu.title }
              className={ classes.listItemText }
            />
          )}
        </>
      )

      if (menu.defaultPath) {
        content = (
          <NavLink
            to={ menu.defaultPath }
            exact={ menu.exact }
            className={ clsx(classes.item, classes.itemColor) }
            activeClassName={ classes.itemActive }
          >
            {content}
          </NavLink>
        )
      }

      return content
    },
    [classes, menu, menuOpen]
  )

  const listItem = useMemo(
    () => (
      <ListItem
          // eslint-disable-next-line no-unneeded-ternary
        className={ clsx(
          classes.listItem,
          { [classes.listItemOpen]: menuOpen }
        ) }
      >
        <div className={ classes.item }>
          {item}
        </div>

      </ListItem>
    ),
    [classes, item, menuOpen]
  )

  if (menuOpen) {
    return (
      <div className={ classes.card }>
        {listItem}
      </div>
    )
  }

  return listItem
}

export default ItemMenu

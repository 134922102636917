import { ToastContainer } from 'react-toastify'

import { ReactKeycloakProvider } from '@react-keycloak/web'

import Theme from '@icr/theme'
import {
  keycloak,
  KeycloakProviderInitConfig
} from '@icr/utils/Authentication/Auth'

import 'react-toastify/dist/ReactToastify.css'

import Routes from './routes'

export default (): JSX.Element => (
  <ReactKeycloakProvider authClient={ keycloak } initOptions={ KeycloakProviderInitConfig }>
    <Theme>
      <Routes />
      <div id="portal" />
      <ToastContainer
        position="top-right"
        autoClose={ 3000 }
        hideProgressBar={ false }
        newestOnTop={ false }
        closeOnClick
        rtl={ false }
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Theme>
  </ReactKeycloakProvider>
)

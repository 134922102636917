import {
  useMemo,
  createContext,
  useContext,
  FC,
  lazy
} from 'react'
import { useLocation } from 'react-router-dom'

import find from 'lodash/find'
import filterFP from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import mapFP from 'lodash/fp/map'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import omitBy from 'lodash/omitBy'

import { useKeycloak } from '@react-keycloak/web'
import {
  ViewDashboard,
  NoteTextOutline,
  Bank,
  AutoFix
} from 'mdi-material-ui'

import { ModalsProvider } from '@grupo-tiradentes/core'

import AuthenticatedScreenRouter from './routers/AuthenticatedScreenRouter'
import {
  Route,
  Routes,
  UseRoutes,
  Menu
} from './types'

const Dashboard = lazy(() => import('@icr/web/src/pages/Dashboard'))
const Instituicoes = lazy(() => import('@icr/web/src/pages/Instituicoes'))
const CadastrarInstituicao = lazy(() => import('@icr/web/src/pages/Instituicoes/CadastrarInstituicao'))
const AtualizarInstituicao = lazy(() => import('@icr/web/src/pages/Instituicoes/AtualizarInstituicao'))
const Titulos = lazy(() => import('@icr/web/src/pages/Titulos'))
const CadastrarTitulo = lazy(() => import('@icr/web/src/pages/Titulos/CadastrarTitulo'))
const AtualizarTitulo = lazy(() => import('@icr/web/src/pages/Titulos/AtualizarTitulo'))
const CorrecaoPendente = lazy(() => import('@icr/web/src/pages/CorrecaoPendente'))

const RoutesContext = createContext<UseRoutes>({
  currentPath: undefined,
  currentRoute: undefined,
  routes: undefined,
  menus: []
})

export const useRoutes = (): UseRoutes => useContext(RoutesContext)

export const AuthenticatedRoutesProvider: FC = () => {
  const { pathname: currentPath } = useLocation()
  const { keycloak } = useKeycloak()
  const resourceAccessRoles = keycloak.resourceAccess[process.env.REACT_APP_KEYCLOAK_RESOURCE]?.roles

  const routes = useMemo<Routes>(
    () => {
      const appRoutes: Routes = {
        Dashboard: {
          path: '/dashboard',
          exact: true,
          roles: ['icrLogged'],
          Component: Dashboard
        },
        Instituicoes: {
          path: '/instituicoes',
          exact: true,
          roles: ['icrLogged'],
          Component: Instituicoes
        },
        CadastrarInstituicoes: {
          path: '/instituicoes/cadastrar',
          exact: true,
          roles: ['icrAuth'],
          Component: CadastrarInstituicao
        },
        AtualizarInstituicoes: {
          path: '/instituicoes/atualizar/:instituicao',
          exact: true,
          roles: ['icrAuth'],
          Component: AtualizarInstituicao
        },
        Titulos: {
          path: '/titulos',
          exact: true,
          roles: ['icrLogged'],
          Component: Titulos
        },
        CadastrarTitulo: {
          path: '/titulos/cadastrar',
          exact: true,
          roles: ['icrAuth'],
          Component: CadastrarTitulo
        },
        AtualizarTitulo: {
          path: '/titulos/atualizar/:nome',
          exact: true,
          roles: ['icrAuth'],
          Component: AtualizarTitulo
        },
        CorrecaoPendente: {
          path: '/pendentes',
          exact: true,
          roles: ['icrPendingFixer', 'icrLogged', 'icrAuth'],
          Component: CorrecaoPendente
        },
      }

      const findRole = (role: string) => resourceAccessRoles?.some((c) => c === role)
      const responseOmitBy = omitBy(appRoutes, (route) => !find(route.roles, findRole))

      if (isEmpty(responseOmitBy)) {
        return {
          naoAutorizado: {
            path: '/',
            exact: true,
            Component: () => <h1>Você não tem permissão para acessar o sistema</h1>
          }
        }
      }
      return responseOmitBy
    },
    [resourceAccessRoles]
  )

  const menus = useMemo(
    (): Menu[] => {
      const items: Menu[] = [
        {
          key: 'dashboard',
          title: 'Dashboard',
          Icon: ViewDashboard,
          defaultPath: routes.Dashboard?.path
        },
        {
          key: 'instituicoes',
          title: 'Instituições',
          Icon: Bank,
          defaultPath: routes.Instituicoes?.path
        },
        {
          key: 'titulos',
          title: 'Titulos',
          Icon: NoteTextOutline,
          defaultPath: routes.Titulos?.path
        },
        {
          key: 'pendentes',
          title: 'Correções Pendentes',
          Icon: AutoFix,
          defaultPath: routes.CorrecaoPendente?.path
        },
      ]

      return flow(
        mapFP((item: Menu) => ({
          exact: false,
          ...item,
          defaultPath: item.defaultPath
        })),
        filterFP((item: Menu) => item.defaultPath !== undefined)
      )(items)
    },
    [routes]
  )

  const currentRoute = useMemo(
    (): Route => find(map(routes, (item) => item), { path: currentPath }) || {},
    [routes, currentPath]
  )

  const toolkit = useMemo(
    (): UseRoutes => ({
      currentPath,
      currentRoute,
      routes,
      menus
    }),
    [currentPath, currentRoute, routes, menus]
  )

  return (
    <RoutesContext.Provider value={ toolkit }>
      <ModalsProvider>
        <AuthenticatedScreenRouter />
      </ModalsProvider>
    </RoutesContext.Provider>
  )
}

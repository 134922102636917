import Keycloak from 'keycloak-js'

const KeycloakConfig = JSON.parse(process.env.REACT_APP_KEYCLOAK_JSON)
export const keycloak = Keycloak({
  url: KeycloakConfig['auth-server-url'],
  realm: KeycloakConfig.realm,
  clientId: KeycloakConfig.resource
})

export const KeycloakProviderInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: 'login-required',
  checkLoginIframe: false
}

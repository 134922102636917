import {
  useMemo,
  Suspense,
  FC
} from 'react'
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import first from 'lodash/first'
import filterFP from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import mapFP from 'lodash/fp/map'
import map from 'lodash/map'

import { Loader } from '@grupo-tiradentes/web'

import {
  Routes,
  Route as IRoute
} from '@icr/web/src/routes/types'

interface Props {
  routes: Routes;
  escapeTo?: string;
}

const RouterSwitch: FC<Props> = (props) => {
  const { routes, escapeTo } = props

  const escape: string = useMemo(
    () => {
      const routesAsArr = map(routes, (r): IRoute => r)
      const firstItem = first(routesAsArr)

      return escapeTo || firstItem?.path || '/'
    },
    [escapeTo, routes]
  )

  const enabledRoutes = useMemo(
    () => flow(
      filterFP((route: IRoute) => !!route.Component),
      mapFP((route: IRoute) => (
        <Route
          key={ route.path }
          path={ route.path }
          exact={ !!route.exact }
          component={ route.Component }
        />
      )),
    )(routes),
    [routes]
  )

  return (
    <Suspense fallback={ <Loader /> }>
      <Switch>
        {enabledRoutes}
        <Route component={ () => <Redirect to={ escape } /> } />
      </Switch>
    </Suspense>
  )
}

export default RouterSwitch

import {
  memo,
  FC
} from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import supportsHistory from '@meta-awesome/functions/src/supportsHistory'
import { useKeycloak } from '@react-keycloak/web'

import { keycloak } from '@icr/utils/Authentication/Auth'
import useGlobalStyles from '@icr/web/src/styles/globalStyles'

import { AuthenticatedRoutesProvider } from './authenticated.routes'
import { GuestRoutesProvider } from './guest.routes'

const forceRefresh = !supportsHistory()

const ScreenRouter: FC = () => {
  useGlobalStyles()

  const { initialized } = useKeycloak()
  const isAuthenticated = keycloak?.authenticated
  const RouterContext = isAuthenticated ? AuthenticatedRoutesProvider : GuestRoutesProvider

  return (
    <Router forceRefresh={ forceRefresh }>
      { initialized ? <RouterContext /> : <p>Carregando...</p>}
    </Router>
  )
}

export default memo(ScreenRouter)
